var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("a-table", {
        attrs: {
          columns: _vm.tableList,
          "data-source": _vm.list,
          pagination: _vm.pagination,
          rowKey: function(record, index) {
            return index
          }
        },
        on: { change: _vm.changePage },
        scopedSlots: _vm._u([
          {
            key: "itemOrderNoSlot",
            fn: function(row) {
              return _c("div", {}, [
                _c(
                  "span",
                  {
                    staticClass: "cur-pot color-blue",
                    on: {
                      click: function($event) {
                        return _vm.handleGoToOrder(row.orderNo)
                      }
                    }
                  },
                  [_vm._v(_vm._s(row.orderNo))]
                )
              ])
            }
          },
          {
            key: "itemOrderRateServicePayTypeSlot",
            fn: function(row) {
              return _c(
                "div",
                {},
                [
                  _c(
                    "a-tooltip",
                    [
                      _c("template", { slot: "title" }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.computedRateServicePayTypeTextByDesc(
                                row.orderRatingServiceRank
                              )
                            ) +
                            " "
                        )
                      ]),
                      _c(
                        "span",
                        {
                          class:
                            _vm.dqCheckPayClassType[row.orderRatingServiceRank]
                        },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.computedRateServicePayTypeText(
                                row.orderRatingServiceRank
                              )
                            )
                          )
                        ]
                      )
                    ],
                    2
                  )
                ],
                1
              )
            }
          },
          {
            key: "itemCheckResultSlot",
            fn: function(row) {
              return _c("div", {}, [
                _c(
                  "span",
                  { class: _vm.rateResultClassType[row.identificationResults] },
                  [
                    _vm._v(
                      _vm._s(
                        _vm.computedResultTypeText(row.identificationResults) ||
                          "暂无"
                      )
                    )
                  ]
                )
              ])
            }
          },
          {
            key: "itemRatingCodeSlot",
            fn: function(row) {
              return _c("div", {}, [
                _c("span", [_vm._v(_vm._s(row.ratingCode))]),
                _c(
                  "div",
                  [
                    _c(
                      "a-button",
                      {
                        staticClass: "mt-10",
                        attrs: { type: "primary", size: "small" },
                        on: {
                          click: function($event) {
                            return _vm.handleShowChangeRatingCodePopup(row)
                          }
                        }
                      },
                      [_vm._v("编辑")]
                    )
                  ],
                  1
                )
              ])
            }
          },
          {
            key: "itemBuyerSelectedSlot",
            fn: function(row) {
              return _c("div", {}, [
                _c(
                  "span",
                  {
                    class:
                      _vm.buyerSelAfterRatingClassType[row.buyerSelAfterRating]
                  },
                  [
                    _vm._v(
                      _vm._s(
                        _vm.computedBuyerSelAfterRatingTypeListMapText(
                          row.buyerSelAfterRating
                        )
                      )
                    )
                  ]
                )
              ])
            }
          },
          {
            key: "itemSenderExpressNoSlot",
            fn: function(row) {
              return _c("div", {}, [
                _c(
                  "span",
                  {
                    class: row.senderExpressNo
                      ? "color-green cur-pot"
                      : "color-gray"
                  },
                  [
                    _vm._v(
                      _vm._s(
                        row.senderExpressNo ? row.senderExpressNo : "未发货"
                      )
                    )
                  ]
                )
              ])
            }
          },
          {
            key: "itemReturnSelectedSlot",
            fn: function(row) {
              return _c("div", {}, [
                _c(
                  "span",
                  {
                    class:
                      _vm.buyerSelAfterRatingClassType[
                        row.sellerSelReturnAfterBuyer
                      ]
                  },
                  [
                    _vm._v(
                      _vm._s(
                        _vm.computedSellerSelReturnAfterBuyerTypeListMapText(
                          row.sellerSelReturnAfterBuyer
                        )
                      )
                    )
                  ]
                )
              ])
            }
          },
          {
            key: "itemDqConfirmSlot",
            fn: function(row) {
              return _c("div", {}, [
                _c("span", { class: _vm.confirmClassType[row.reviewStatus] }, [
                  _vm._v(_vm._s(_vm.computedConfirmTypeText(row.reviewStatus)))
                ])
              ])
            }
          },
          {
            key: "itemActionSlot",
            fn: function(row) {
              return _c(
                "div",
                {},
                [
                  _c(
                    "a-button",
                    {
                      attrs: { size: "small", type: "primary" },
                      on: {
                        click: function($event) {
                          return _vm.handleShowConfirm(row)
                        }
                      }
                    },
                    [_vm._v("请确认")]
                  )
                ],
                1
              )
            }
          }
        ])
      }),
      _c("ConfirmDetailPopup", {
        ref: "confirmDetailPopupEl",
        on: { success: _vm.handleConfirmSuccess }
      }),
      _c("EditorRatingCodePopup", {
        ref: "editorRatingCodePopupEl",
        attrs: { "rate-type": "ABC" },
        on: { change: _vm.handleConfirmSuccess }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }