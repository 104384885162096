import {matchGongStr} from "@/untils/otherEvent"

export default {
    data() {
        return {
            barCodeFocus: false,
            ratingCodeFocus: false
        }
    },
    methods: {
        /** 扫码录入数据 */
        handleScanCodeGetData(code) {
            if (this.barCodeFocus || this.ratingCodeFocus) {
                if (this.barCodeFocus) {
                    this.params.barCode = code
                }
                if (this.ratingCodeFocus) this.params.ratingCode = matchGongStr(code)
            } else {
                if (!this.params.barCode) {
                    this.params.barCode = code
                }else if (!this.params.ratingCode && !!this.params.barCode) {
                    this.$set(this.params, 'ratingCode', matchGongStr(code))
                } else if (this.params.barCode && this.params.ratingCode) {
                    this.params.barCode = undefined
                    this.params.ratingCode = undefined
                    this.params.barCode = code
                }
            }
        },
        /** 产品条码聚焦 */
        handleBarCodeFocus() {
            this.barCodeFocus = true
        },
        /** 产品条码失焦 */
        handleBarCodeBlur() {
            this.barCodeFocus = false
        },
        /** 华夏条码聚焦 */
        handleRatingCodeFocus() {
            this.ratingCodeFocus = true
        },
        /** 华夏条秒失焦 */
        handleRatingCodeBlur() {
            this.ratingCodeFocus = false
        },
    }
}