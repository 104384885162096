<template>
  <a-modal
      v-model="showPopup"
      title="修改评级编号"
      :width="800"
      ok-text="确认"
      cancel-text="取消"
      @cancel="handleCancel"
      @ok="handleConfirm"
  >
    <div class="popup-content">
      <a-form-model
          ref="ruleForm"
          :model="params"
          v-bind="layout"
      >

        <a-form-item label="旧的评级编号">
          <a-input
              allowClear
              disabled
              v-model="oldRatingCode"
              class="w-200"
              placeholder='旧的评级编号'
          ></a-input>
        </a-form-item>
        <a-form-item label="新的的评级编号">
          <a-input
              allowClear
              v-model="params.ratingCode"
              class="w-200"
              placeholder='新的评级编号'
          ></a-input>
        </a-form-item>
      </a-form-model>
    </div>
  </a-modal>
</template>
<script>
export default {
  props: ['rateType'],
  data() {
    return {
      showPopup: false,
      bondPriceId: '',
      layout: {
        labelCol: { span: 4 }, //标签占5份  共24份
        wrapperCol: { span: 20 },
      },
      oldRatingCode: undefined,
      params: {
        ratingId: undefined,
        ratingCode: undefined,
      },
      apiUrl: {
        ABC: '/dq_admin/qualityzhandian/updateRatingCode',
        D: '/dq_admin/personRatingApply/updateRatingCode'
      }
    }
  },
  methods: {
    /** 展示弹窗 */
    show(data) {
      this.oldRatingCode = data.ratingCode
      this.params.ratingId = data.ratingId
      this.showPopup = true
    },
    /** 取消 */
    handleCancel() {
      this.oldRatingCode = undefined
      this.params = {
        ratingId: undefined,
        ratingCode: undefined,
      }
      this.showPopup = false
    },
    /** 确定 */
    async handleConfirm() {
      if (!this.params.ratingCode) {
        return this.$message.warn('评级编号不能为空')
      }
      this.$loading.show()
      let temp = {}
      if (this.rateType === 'D') {
        temp.barCodeId = this.params.ratingId
        temp.newRatingCode = this.params.ratingCode
        temp.oldRatingCode = this.oldRatingCode
      } else {
        temp.ratingId = this.params.ratingId
        temp.ratingCode = this.params.ratingCode
      }
      const res = await this.axios(this.apiUrl[this.rateType], {
        params: temp
      })
      this.$loading.hide()
      if (res.status !== '200') return
      this.$emit('change')
      this.handleCancel()
    }
  }
}
</script>