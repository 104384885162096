var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "a-modal",
        {
          attrs: { width: 800, title: "确认评级结果" },
          on: { cancel: _vm.resetForms, ok: _vm.hideModal },
          scopedSlots: _vm._u([
            {
              key: "footer",
              fn: function() {
                return [
                  _c(
                    "a-button",
                    { key: "back", on: { click: _vm.resetForms } },
                    [_vm._v("取消")]
                  ),
                  _c(
                    "a-popconfirm",
                    {
                      attrs: {
                        title: "一经确定将不可回退，确定操作？",
                        "ok-text": "Yes",
                        "cancel-text": "No"
                      },
                      on: { confirm: _vm.hideModal }
                    },
                    [
                      _c(
                        "a-button",
                        { key: "submit", attrs: { type: "primary" } },
                        [_vm._v("确定")]
                      )
                    ],
                    1
                  )
                ]
              },
              proxy: true
            }
          ]),
          model: {
            value: _vm.show,
            callback: function($$v) {
              _vm.show = $$v
            },
            expression: "show"
          }
        },
        [
          _c(
            "div",
            { staticClass: "popup-content" },
            [
              _c("div", { staticClass: "content-title" }, [
                _c("div", { staticClass: "title-result-text" }, [
                  _vm._v(" 评级结果： "),
                  _c(
                    "span",
                    {
                      class: _vm.rateResultClassType[_vm.identificationResults]
                    },
                    [
                      _vm._v(
                        _vm._s(
                          _vm.computedResultTypeText(
                            _vm.identificationResults
                          ) || "暂无"
                        )
                      )
                    ]
                  )
                ]),
                _c("div", { staticClass: "title-result-text ml-20" }, [
                  _vm._v("评级内容："),
                  _c("span", [_vm._v(_vm._s(_vm.detailData.remark || "暂无"))])
                ]),
                _c("div", { staticClass: "title-result-text ml-20" }, [
                  _vm._v("赔付等级："),
                  _c("span", [
                    _vm._v(_vm._s(_vm.detailData.payLevel || "暂无"))
                  ])
                ]),
                _c("div", { staticClass: "title-result-text ml-20" }, [
                  _vm._v("分数："),
                  _c("span", [_vm._v(_vm._s(_vm.detailData.score || "暂无"))])
                ]),
                _c("div", { staticClass: "title-result-text ml-20" }, [
                  _vm._v("评级尺寸："),
                  _c("span", [_vm._v(_vm._s(_vm.detailData.size || "暂无"))])
                ]),
                _c("div", { staticClass: "title-result-text ml-20" }, [
                  _vm._v("版别："),
                  _c("span", [
                    _vm._v(
                      _vm._s(
                        _vm.detailData.title + " " + _vm.detailData.version
                      )
                    )
                  ])
                ]),
                _c("div", { staticClass: "title-result-text" }, [
                  _vm._v("评级编号："),
                  _c("span", { staticClass: "text-underline" }, [
                    _vm._v(_vm._s(_vm.detailData.boxCode))
                  ])
                ])
              ]),
              _c("div", { staticClass: "content-result-img" }, [
                _c("div", { staticClass: "result-img-list" }, [
                  _c("div", { staticClass: "result-img-title ft-sz-15" }, [
                    _vm._v("评级图片")
                  ]),
                  _c(
                    "div",
                    { staticClass: "result-img-list" },
                    _vm._l(_vm.detailData.officialWebsiteImage, function(
                      item,
                      index
                    ) {
                      return _c("img", {
                        key: index,
                        attrs: { src: item, alt: "" },
                        on: {
                          click: function($event) {
                            return _vm.handlePreview(
                              _vm.detailData.officialWebsiteImage,
                              index
                            )
                          }
                        }
                      })
                    }),
                    0
                  )
                ])
              ]),
              _c("div", { staticClass: "content-check-img" }, [
                _c("div", { staticClass: "result-img-list" }, [
                  _c("div", { staticClass: "result-img-title" }, [
                    _vm._v("质检图片")
                  ]),
                  _c(
                    "div",
                    { staticClass: "result-img-list" },
                    _vm._l(_vm.detailData.qualityInspectionImage, function(
                      item,
                      index
                    ) {
                      return _c("img", {
                        key: index,
                        attrs: { src: item, alt: "" },
                        on: {
                          click: function($event) {
                            return _vm.handlePreview(
                              _vm.detailData.qualityInspectionImage,
                              index
                            )
                          }
                        }
                      })
                    }),
                    0
                  )
                ])
              ]),
              _c("div", { staticClass: "content-check-img" }, [
                _c("div", { staticClass: "result-img-list" }, [
                  _c("div", { staticClass: "result-img-title" }, [
                    _vm._v("产品图片")
                  ]),
                  _c(
                    "div",
                    { staticClass: "result-img-list" },
                    _vm._l(_vm.detailData.productImage, function(item, index) {
                      return _c("img", {
                        key: index,
                        attrs: { src: item, alt: "" },
                        on: {
                          click: function($event) {
                            return _vm.handlePreview(
                              _vm.detailData.productImage,
                              index
                            )
                          }
                        }
                      })
                    }),
                    0
                  )
                ])
              ]),
              _c("div", { staticClass: "content-check-img" }, [
                _vm.productName
                  ? _c("div", { staticClass: "result-img-list mt-10" }, [
                      _c("div", { staticClass: "result-img-title" }, [
                        _vm._v("拍品名称：")
                      ]),
                      _c("span", { staticClass: "color-red" }, [
                        _vm._v(_vm._s(_vm.productName || "无"))
                      ])
                    ])
                  : _vm._e(),
                _vm.orderPrice
                  ? _c("div", { staticClass: "result-img-list mt-10" }, [
                      _c("div", { staticClass: "result-img-title" }, [
                        _vm._v("拍品价格：")
                      ]),
                      _c("span", { staticClass: "color-red" }, [
                        _vm._v(_vm._s("￥" + _vm.orderPrice))
                      ])
                    ])
                  : _vm._e(),
                _c("div", { staticClass: "result-img-list" }, [
                  _c("div", { staticClass: "result-img-title" }, [
                    _vm._v("拍品尺寸：")
                  ]),
                  _c("span", { staticClass: "color-red" }, [
                    _vm._v(
                      _vm._s(
                        _vm.detailData.productSize || "卖家没有填写拍品尺寸"
                      )
                    )
                  ])
                ]),
                _c("div", { staticClass: "result-img-list" }, [
                  _c("div", { staticClass: "result-img-title" }, [
                    _vm._v("拍品瑕疵：")
                  ]),
                  _c(
                    "span",
                    { class: _vm.productRemark ? "color-red" : "color-gray" },
                    [_vm._v(_vm._s(_vm.productRemark || "卖家没有填写瑕疵"))]
                  )
                ]),
                _c(
                  "div",
                  { staticClass: "result-img-list" },
                  [
                    _c("div", { staticClass: "result-img-title" }, [
                      _vm._v("是否标版：")
                    ]),
                    _c("span", { staticClass: "color-green" }, [
                      _vm._v(
                        _vm._s(
                          _vm.detailData.refundedBiaobanStatus === 1
                            ? "标版"
                            : "无需标版"
                        )
                      )
                    ]),
                    _vm.detailData.refundedBiaobanStatus !== 0
                      ? _c(
                          "a-checkbox",
                          {
                            staticClass: "ml-20",
                            attrs: {
                              disabled:
                                _vm.detailData.refundedBiaobanStatus === 2,
                              checked: _vm.checkReturnVersionPriceStatus
                            },
                            on: { change: _vm.handleChangeCheckBox }
                          },
                          [
                            _vm._v(" 是否退标版费 "),
                            _vm.detailData.refundedBiaobanStatus === 2
                              ? _c("span", { staticClass: "color-gray" }, [
                                  _vm._v("（已退）")
                                ])
                              : _vm._e()
                          ]
                        )
                      : _vm._e()
                  ],
                  1
                )
              ]),
              _c(
                "div",
                { staticClass: "mt-10", staticStyle: { "font-size": "20px" } },
                [
                  _c(
                    "a-tooltip",
                    [
                      _c("template", { slot: "title" }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.computedRateServicePayTypeTextByDesc(
                                _vm.orderRatingServiceRank
                              )
                            ) +
                            " "
                        )
                      ]),
                      _c(
                        "span",
                        {
                          class:
                            _vm.dqCheckPayClassType[_vm.orderRatingServiceRank]
                        },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.computedRateServicePayTypeText(
                                _vm.orderRatingServiceRank
                              )
                            )
                          )
                        ]
                      )
                    ],
                    2
                  )
                ],
                1
              ),
              _c("a-divider", [_vm._v("确认评级审核结果")]),
              _c(
                "div",
                { staticClass: "result-select-list" },
                [
                  _c(
                    "a-select",
                    {
                      staticStyle: { width: "150px" },
                      attrs: {
                        allowClear: "",
                        showSearch: "",
                        placeholder: "选择结果类型"
                      },
                      on: { change: _vm.handleChangeReviewStatus },
                      model: {
                        value: _vm.params.reviewStatus,
                        callback: function($$v) {
                          _vm.$set(_vm.params, "reviewStatus", $$v)
                        },
                        expression: "params.reviewStatus"
                      }
                    },
                    _vm._l(_vm.confirmResultTypeList, function(item) {
                      return _c(
                        "a-select-option",
                        { key: item.value, attrs: { value: item.value } },
                        [_vm._v(_vm._s(item.name))]
                      )
                    }),
                    1
                  ),
                  _vm.showDescTypeStatus
                    ? _c(
                        "a-select",
                        {
                          staticStyle: {
                            width: "150px",
                            "margin-left": "10px"
                          },
                          attrs: {
                            allowClear: "",
                            showSearch: "",
                            placeholder: "选择描述类型"
                          },
                          on: { change: _vm.handleChangeDescTypeStatus },
                          model: {
                            value: _vm.params.responsibilityDetermine,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.params,
                                "responsibilityDetermine",
                                $$v
                              )
                            },
                            expression: "params.responsibilityDetermine"
                          }
                        },
                        _vm._l(_vm.computedWatchLevelTwoList, function(item) {
                          return _c(
                            "a-select-option",
                            { key: item.value, attrs: { value: item.value } },
                            [_vm._v(_vm._s(item.name))]
                          )
                        }),
                        1
                      )
                    : _vm._e(),
                  _vm.showAddressSelectedType
                    ? _c(
                        "a-select",
                        {
                          staticStyle: {
                            width: "150px",
                            "margin-left": "10px"
                          },
                          attrs: {
                            allowClear: "",
                            showSearch: "",
                            placeholder: "选择给那个用户"
                          },
                          on: { change: _vm.handleChangeAddressSelectedType },
                          model: {
                            value: _vm.params.addressSelectedType,
                            callback: function($$v) {
                              _vm.$set(_vm.params, "addressSelectedType", $$v)
                            },
                            expression: "params.addressSelectedType"
                          }
                        },
                        _vm._l(_vm.addressSelectedTypeList, function(item) {
                          return _c(
                            "a-select-option",
                            { key: item.value, attrs: { value: item.value } },
                            [_vm._v(_vm._s(item.name))]
                          )
                        }),
                        1
                      )
                    : _vm._e()
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "input-ratingPrice" },
                [
                  _vm._v(" 评级费： "),
                  _c(
                    "a-select",
                    {
                      staticStyle: { width: "150px", "margin-left": "10px" },
                      attrs: {
                        allowClear: "",
                        showSearch: "",
                        placeholder: "选择评级费金额"
                      },
                      model: {
                        value: _vm.params.ratingPrice,
                        callback: function($$v) {
                          _vm.$set(_vm.params, "ratingPrice", $$v)
                        },
                        expression: "params.ratingPrice"
                      }
                    },
                    _vm._l(_vm.ratePriceLevelTypeList, function(item) {
                      return _c(
                        "a-select-option",
                        { key: item.value, attrs: { value: item.value } },
                        [_vm._v(_vm._s(item.name))]
                      )
                    }),
                    1
                  )
                ],
                1
              ),
              _vm.showSellCompensateBuyerPriceStatus
                ? _c(
                    "div",
                    { staticClass: "input-ratingPrice" },
                    [
                      _vm._v(" 卖家是否补偿买家费用： "),
                      _c(
                        "a-select",
                        {
                          staticStyle: {
                            width: "250px",
                            "margin-left": "10px"
                          },
                          attrs: {
                            allowClear: "",
                            showSearch: "",
                            placeholder: "卖家是否补偿买家费用"
                          },
                          on: { change: _vm.handleChangeSellerBear },
                          model: {
                            value: _vm.params.sellerBear,
                            callback: function($$v) {
                              _vm.$set(_vm.params, "sellerBear", $$v)
                            },
                            expression: "params.sellerBear"
                          }
                        },
                        _vm._l(
                          _vm.sellerCompensateBuyerPriceStatusTypeList,
                          function(item) {
                            return _c(
                              "a-select-option",
                              { key: item.value, attrs: { value: item.value } },
                              [_vm._v(_vm._s(item.name))]
                            )
                          }
                        ),
                        1
                      ),
                      _vm.params.sellerBear === 1
                        ? _c("a-input", {
                            staticStyle: {
                              width: "200px",
                              "margin-left": "10px"
                            },
                            attrs: { placeholder: "补偿金额50的倍数" },
                            model: {
                              value: _vm.params.compensationAmount,
                              callback: function($$v) {
                                _vm.$set(_vm.params, "compensationAmount", $$v)
                              },
                              expression: "params.compensationAmount"
                            }
                          })
                        : _vm._e()
                    ],
                    1
                  )
                : _vm._e(),
              _vm.showDeductAllBondPriceBySellerStatus
                ? _c(
                    "div",
                    { staticClass: "input-ratingPrice" },
                    [
                      _vm._v(" 是否扣除卖家保证金： "),
                      _c(
                        "a-select",
                        {
                          staticStyle: {
                            width: "250px",
                            "margin-left": "10px"
                          },
                          attrs: {
                            allowClear: "",
                            showSearch: "",
                            placeholder: "请选择是否扣除卖家保证金"
                          },
                          on: { change: _vm.handleChangeDeductionOfFullAmount },
                          model: {
                            value: _vm.params.deductionOfFullAmount,
                            callback: function($$v) {
                              _vm.$set(_vm.params, "deductionOfFullAmount", $$v)
                            },
                            expression: "params.deductionOfFullAmount"
                          }
                        },
                        _vm._l(
                          _vm.deductAllBondPriceBySellerStatusTypeList,
                          function(item) {
                            return _c(
                              "a-select-option",
                              { key: item.value, attrs: { value: item.value } },
                              [_vm._v(_vm._s(item.name))]
                            )
                          }
                        ),
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "div",
                { staticClass: "result-desc-remark" },
                [
                  _c("a-textarea", {
                    attrs: { placeholder: "售后定责描述" },
                    model: {
                      value: _vm.params.reviewRemarks,
                      callback: function($$v) {
                        _vm.$set(_vm.params, "reviewRemarks", $$v)
                      },
                      expression: "params.reviewRemarks"
                    }
                  }),
                  _c("div", { staticClass: "color-red" }, [
                    _vm._v("*请谨慎填写")
                  ]),
                  _vm.quickInputDescTextList.length
                    ? _c(
                        "div",
                        { staticClass: "w-100-w mt-10" },
                        _vm._l(_vm.quickInputDescTextList, function(
                          item,
                          index
                        ) {
                          return _c(
                            "a-tag",
                            {
                              key: index,
                              staticClass: "mr-5 cur-pot mb-10",
                              attrs: { color: "blue" },
                              on: {
                                click: function($event) {
                                  return _vm.handleInputDesc(item)
                                }
                              }
                            },
                            [_vm._v(_vm._s(item))]
                          )
                        }),
                        1
                      )
                    : _vm._e()
                ],
                1
              ),
              _c("a-divider", [_vm._v("地址")]),
              _c("div", { staticClass: "result-detail-address" }, [
                _vm.buyerAddress && _vm.params.addressSelectedType === 1
                  ? _c("div", { staticClass: "buyer-seller-address" }, [
                      _c("div", { staticClass: "address-title" }, [
                        _vm._v("买家地址：")
                      ]),
                      _c("div", { staticClass: "address-info-text" }, [
                        _vm._v("用户姓名："),
                        _c("span", [_vm._v(_vm._s(_vm.buyerAddress.userName))])
                      ]),
                      _c("div", { staticClass: "address-info-text" }, [
                        _vm._v("用户电话："),
                        _c("span", [_vm._v(_vm._s(_vm.buyerAddress.telNumber))])
                      ]),
                      _c("div", { staticClass: "address-info-text" }, [
                        _vm._v("省市区："),
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm.buyerAddress.provinceName +
                                " " +
                                _vm.buyerAddress.cityName +
                                " " +
                                _vm.buyerAddress.countyName
                            )
                          )
                        ])
                      ]),
                      _c("div", { staticClass: "address-info-text" }, [
                        _vm._v("地址详情："),
                        _c("span", [
                          _vm._v(_vm._s(_vm.buyerAddress.detailInfo))
                        ])
                      ])
                    ])
                  : _vm._e(),
                _vm.sellerAddress && _vm.params.addressSelectedType === 2
                  ? _c("div", { staticClass: "buyer-seller-address" }, [
                      _c("div", { staticClass: "address-title" }, [
                        _vm._v("卖家地址：")
                      ]),
                      _c("div", { staticClass: "address-info-text" }, [
                        _vm._v("用户姓名："),
                        _c("span", [_vm._v(_vm._s(_vm.sellerAddress.userName))])
                      ]),
                      _c("div", { staticClass: "address-info-text" }, [
                        _vm._v("用户电话："),
                        _c("span", [
                          _vm._v(_vm._s(_vm.sellerAddress.telNumber))
                        ])
                      ]),
                      _c("div", { staticClass: "address-info-text" }, [
                        _vm._v("省市区："),
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm.sellerAddress.provinceName +
                                " " +
                                _vm.sellerAddress.cityName +
                                " " +
                                _vm.sellerAddress.countyName
                            )
                          )
                        ])
                      ]),
                      _c("div", { staticClass: "address-info-text" }, [
                        _vm._v("地址详情："),
                        _c("span", [
                          _vm._v(_vm._s(_vm.sellerAddress.detailInfo))
                        ])
                      ])
                    ])
                  : _vm._e()
              ])
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }