<template>
  <div class="content">
    <div class="top">
      <a-input
          allowClear
          v-model="params.barCode"
          class="w-200"
          @focus="handleBarCodeFocus"
          @blur="handleBarCodeBlur"
          placeholder='产品条码'
      ></a-input>
      <a-input
          allowClear
          v-model="params.ratingCode"
          class="w-200 ml-5"
          @focus="handleRatingCodeFocus"
          @blur="handleRatingCodeBlur"
          placeholder='评级编码'
      ></a-input>
      <a-input
          allowClear
          v-model="params.orderNo"
          class="w-200 ml-5"
          placeholder='订单号'
      ></a-input>
      <a-input
          allowClear
          v-model="params.shortCode"
          class="w-150 ml-5"
          placeholder='输入简短编码'
      ></a-input>
      <a-select
          allowClear
          showSearch
          class="w-150 ml-5"
          v-model="params.identificationResults"
          placeholder="评级结果类型"
      >
        <a-select-option
            v-for="item of rateResultTypeList"
            :key="item.value"
            :value="item.value"
        >{{ item.name }}</a-select-option>
      </a-select>
      <a-select
          class="w-200 ml-5"
          v-model="params.reviewStatus"
          placeholder="确认状态"
          @change="handleChangeReviewStatus"
      >
        <a-select-option
            v-for="item of confirmTypeList"
            :key="item.value"
            :value="item.value"
        >{{ item.name }}</a-select-option>
      </a-select>
      <a-select
          v-if="isShowSelectedByBuyerOrSellerStatus"
          class="w-200 ml-5"
          allowClear
          v-model="params.selectedStatus"
          placeholder="选择买卖双方是否选择"
      >
        <a-select-option
            v-for="item of selectedByBuyerOrSeller"
            :key="item.value"
            :value="item.value"
        >{{ item.name }}</a-select-option>
      </a-select>
      <a-button
          type="primary"
          @click="search"
      >搜索</a-button>
    </div>
    <div class="table-content">
      <TableList
          :list="data"
          :pagination="pagination"
          @changePage="handleChangePage"
          @success="getList"
      />
    </div>
  </div>
</template>
<script>
import TableList from "@/views/cmsPage/checkManage/resultList/_components/TableList"
import {
  CONFIRM_TYPE_LIST,
  RATE_RESULT_TYPE_LIST,
  SELECTED_BY_BUYER_SELLER_TYPE_LIST
} from "@/views/cmsPage/checkManage/_data"
import ScanCode from "@/views/cmsPage/checkManage/resultList/_mixin/ScanCode"
import {destroyKeyPress, scanCodeWatch} from "@/untils/otherEvent"

export default {
  components: {
    TableList,
  },
  mixins: [ScanCode],
  data() {
    return {
      data: [],
      tempData: [],
      rateResultTypeList: RATE_RESULT_TYPE_LIST,
      confirmTypeList: CONFIRM_TYPE_LIST,
      selectedByBuyerOrSeller: SELECTED_BY_BUYER_SELLER_TYPE_LIST,
      pagination: {
        total: 0,
        current: 1,
        pageSize: 10,
        showTotal: (total) => `共${total}条`,
      },
      params: {
        pageNum: 1,
        pageSize: 10,
        barCode: undefined,
        ratingCode: undefined,
        orderNo: undefined,
        // 鉴定结果
        identificationResults: undefined,
        // 确认状态
        reviewStatus: 0,
        shortCode: undefined,
        // 买卖双方是否需要选择
        selectedStatus: undefined
      },
      isShowSelectedByBuyerOrSellerStatus: false
    };
  },
  async mounted() {
    const { orderNo, reviewStatus } = this.$route.query
    this.$set(this.params, "orderNo", orderNo)
    this.$set(this.params, "reviewStatus", reviewStatus)
    await this.getList()
    scanCodeWatch(this.handleScanCodeGetData)
  },
  beforeDestroy() {
    destroyKeyPress()
  },
  methods: {
    /** 改变确认状态 */
    handleChangeReviewStatus(e) {
      if (e === 1) {
        this.isShowSelectedByBuyerOrSellerStatus = true
      } else {
        this.isShowSelectedByBuyerOrSellerStatus = false
        this.params.selectedStatus = undefined
      }
    },
    /** 翻页 */
    handleChangePage(page) {
      const { current } = page;
      this.$set(this.pagination, "current", current);
      this.$set(this.params, "pageNum", current);
      this.getList();
    },
    /** 搜索 */
    async search() {
      this.$set(this.pagination, "current", 1);
      this.$set(this.params, "pageNum", 1);
      await this.getList();
      this.params.barCode = undefined
      this.params.ratingCode = undefined
      this.params.shortCode = undefined
    },
    /**  获取列表数据 */
    async getList() {
      this.$loading.show()
      const res = await this.axios("/dq_admin/identificationResults/list", {
        params: {
          ...this.params
        },
      });
      this.$loading.hide()
      if (res.status != 200) return;
      this.data = res.data.records;
      this.pagination.total = res.data.total;
    },
  },
};
</script>
<style lang="scss" scoped>
.select-all-text {
  margin-left: 20px;
  margin-right: 20px;
}
.content {
  display: flex;
  flex-direction: column;
  .top-search {
    display: flex;
    align-items: center;
  }
  .table-content {
    flex: 1;
    overflow-y: auto;
  }
}

.top {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 20px;
  input {
    width: 250px;
    margin-right: 25px;
    margin-bottom: 20px;
  }
  button {
    margin-left: 10px;
  }
}

.pagination {
  text-align: right;
  margin-top: 10px;
}
</style>
